import React, { ReactNode, Suspense } from "react";
import { Head, queryClient, useMutation, useQuery, useRouter } from "blitz";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  VStack,
  MenuDivider,
  useDisclosure
} from "@chakra-ui/react";
import { ChevronDownIcon, PlusSquareIcon } from "@chakra-ui/icons";
import { Link as ChakraLink } from "@chakra-ui/react";
import { Link, LinkButton } from "chakra-next-link";

import getSections from "app/admin/sections/queries/getSections";
import logoutFn from "app/auth/mutations/logout";
import { MAX_PAGE_WIDTH } from "app/core/theme";
import { Logo } from "app/core/components/Logo";
import { useCurrentUser } from "app/core/hooks/useCurrentUser";
import { Modal } from "app/core/components/Modal";

const Sections: React.FC = () => {
  const [sections] = useQuery(getSections, undefined);

  return (
    <VStack align="flex-start">
      <HStack align="flex-start">
        <LinkButton href="/posts">Sve objave</LinkButton>
        {sections.map((section) => (
          <Menu key={section.id}>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              {section.name}
            </MenuButton>
            <MenuList>
              {section.categories.map((category) => (
                <Link href={`/posts?categoryId=${category.id}`} key={category.id}>
                  <MenuItem isDisabled={category.posts.length === 0}>{category.name}</MenuItem>
                </Link>
              ))}
            </MenuList>
          </Menu>
        ))}
      </HStack>
    </VStack>
  );
};

const UserThingy: React.FC = () => {
  const router = useRouter();
  const currentUser = useCurrentUser();
  const [logout] = useMutation(logoutFn);

  const onLogout = async () => {
    queryClient.clear();
    await logout();
    await router.push("/");
  };

  return (
    currentUser && (
      <Menu>
        <MenuButton>
          <Avatar name={currentUser.name || ""} cursor="pointer" />
        </MenuButton>
        <MenuList>
          {/* <MenuItem icon={dark ? <MoonIcon /> : <SunIcon />} onClick={toggleColorMode}>
            <Text>{dark ? "Dark" : "Light"} theme</Text>
          </MenuItem> */}

          {currentUser.role === "Admin" && (
            <>
              <Link href="/posts/new">
                <MenuItem icon={<PlusSquareIcon />}>Nova objava</MenuItem>
              </Link>
              <Link href="/admin">
                <MenuItem>Admin</MenuItem>
              </Link>

              <MenuDivider />
            </>
          )}

          <Link href="/user/settings">
            <MenuItem>Postavke</MenuItem>
          </Link>
          <MenuItem onClick={onLogout}>Odjava</MenuItem>
        </MenuList>
      </Menu>
    )
  );
};

type LayoutProps = {
  title?: string;
  children: ReactNode;
  hideLoggedInStuff?: boolean;
};

export const Layout: React.FC<LayoutProps> = ({ title, children, hideLoggedInStuff = false }) => {
  const videoModal = useDisclosure();

  return (
    <>
      <Head>
        <title>{title || "med-forum"}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Box h="80px" w="full" py="4" shadow="md">
        <Flex justify="space-between" w="90%" maxW={MAX_PAGE_WIDTH} marginX="auto">
          <Logo />

          {!hideLoggedInStuff && (
            <HStack>
              <Suspense fallback={<Spinner />}>
                <Sections />
              </Suspense>

              <Suspense fallback={<Spinner />}>
                <UserThingy />
              </Suspense>
            </HStack>
          )}
        </Flex>
      </Box>

      <Box minH="calc(100vh - 80px - 120px - 100px - 1px)" pb="100px" pt="2">
        {children}
      </Box>

      <Modal modalProps={videoModal} title="Ja sam više od...">
        <video width="100%" controls autoPlay>
          <source
            src="https://cro-hivid.com/uploads/HIV_video_FINAL_03-2023_ENG.mp4"
            type="video/mp4"
          />
        </video>
      </Modal>

      {!hideLoggedInStuff && (
        <Box as="footer" w="full" borderTopWidth="1px">
          <Image
            alt="BIKTARVY"
            marginX="auto"
            w="800px"
            h="120px"
            src="/footer.gif"
            onClick={videoModal.onOpen}
            cursor="pointer"
          />

          <HStack w="full" justify="center" alignItems="center" spacing="10">
            {/* <ChakraLink href="/updated_DOVATO_OGLAS_2024_HRV_2.pdf">
              <Image alt="GSK ViiV" h="70px" src="/new_gsk.png" />
            </ChakraLink> */}
            <ChakraLink href="https://cro-hivid.com/uploads/BIKTARVY_EnoghSaid_WebOglas_Q32021_01.pdf">
              <Image alt="gilead medicopharma logo" h="100px" src="/gilead_med.png" />
            </ChakraLink>
            {/* <Image alt="MSD" h="70px" src="/thingy.jpg" /> */}
          </HStack>
        </Box>
      )}
    </>
  );
};
